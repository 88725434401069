import { Box, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "next-i18next";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

const P: React.FC = (props: any) => (
  <Typography variant="body1">{props.children}</Typography>
);

const OL: React.FC = (props: any) => {
  const theme = useTheme();
  return (
    <ol
      style={{
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      }}
    >
      {props.children}
    </ol>
  );
};

const LI: React.FC = (props: any) => (
  <li style={{}}>
    <Typography variant="body2">{props.children}</Typography>
  </li>
);

const OrderConfirmationTemplate = () => {
  const { t } = useTranslation("OrderConfirmationView");
  const theme = useTheme();
  const router = useRouter();
  const orderNumber = router.query?.orderNumber?.toString() || "";

  const replace = (s: string) => {
    return s.replace("{orderNumber}", orderNumber);
  };

  return (
    <Box>
      <Paper style={{ margin: theme.spacing(3), padding: theme.spacing(3) }}>
        <Typography variant="h4" component="h1">
          {replace(t("title"))}
        </Typography>
        <div style={{ marginTop: theme.spacing(1) }}>
          <ReactMarkdown
            remarkPlugins={[gfm]}
            components={{ p: P, ol: OL, li: LI }}
          >
            {replace(t("text"))}
          </ReactMarkdown>
        </div>
      </Paper>
    </Box>
  );
};

export default OrderConfirmationTemplate;
