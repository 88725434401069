import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

// export const useSharedStyles = makeStyles((theme: Theme) => ({
//   wrapper: {
//     padding: theme.spacing(3, 0),
//   },
//   title: {
//     fontSize: theme.typography.pxToRem(26),
//     marginBottom: theme.spacing(2),
//     marginTop: theme.spacing(-2),
//   },
//   closeIconWrapper: {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     padding: theme.spacing(2, 2, 0, 2),
//   },
//   closeIcon: {
//     width: 20,
//     height: 20,
//     fill: theme.colors.grey,
//     cursor: 'pointer',
//   },
//   buttonWrapper: {
// width: '100%',
// display: 'flex',
// justifyContent: 'center',
// marginBottom: theme.spacing(2),
//   },
//   inputName: {

//   },
// }))

export const ButtonWrapper = styled(`div`)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

export const InputName = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
}));
