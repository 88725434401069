import theme from "@/utils/theme";
import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const OrderProgressStyles = styled(Box)(() => ({
  display: "flex",
}));

export const Bar = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  justifyContent: "space-between",
  alignItems: "center",
  zIndex: 0,
  height: 12,

  backgroundColor: theme.colors.coolBlueLight,
}));

export const ProgressBar = styled(Box)(() => ({
  position: "absolute",
  backgroundColor: theme.colors.coolBlue,
  transition: "width 0.3s ease",
  left: 0,
  top: 0,
  bottom: 0,
  zIndex: -1,
  borderTopLeftRadius: "4px",
  //   borderTopRightRadius: "4px",
}));

export const Card = styled(Paper)(({ isHidden }: any) => ({
  display: isHidden ? "none" : "inherit",
  backgroundColor: theme.palette.background.paper,
  // boxShadow: theme.shadows[5],
  // padding: theme.spacing(2, 4, 3),
}));

export const CardContent = styled(Box)(() => ({
  //   backgroundColor: theme.palette.background.paper,
  //   boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
}));
