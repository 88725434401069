import AccountTemplate from "./AccountTemplate";
import OrderViewTemplate from "./OrderViewTemplate";
import ProofTemplate from "./ProofTemplate";
import OrderConfirmationTemplate from "./OrderConfirmationTemplate";
import DesignsTemplate from "./DesignsTemplate";
import OrdersListTemplate from "./OrdersListTemplate";

export {
  AccountTemplate,
  OrderViewTemplate,
  ProofTemplate,
  OrderConfirmationTemplate,
  DesignsTemplate,
  OrdersListTemplate,
};
