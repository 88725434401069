const countries = [
  { key: "AF", name: "Afghanistan" },
  { key: "AL", name: "Albania" },
  { key: "DZ", name: "Algeria" },
  { key: "AS", name: "American Samoa" },
  { key: "AD", name: "Andorra" },
  { key: "AO", name: "Angola" },
  { key: "AI", name: "Anguilla" },
  { key: "AQ", name: "Antarctica" },
  { key: "AG", name: "Antigua And Barbuda" },
  { key: "AR", name: "Argentina" },
  { key: "AM", name: "Armenia" },
  { key: "AW", name: "Aruba" },
  { key: "AU", name: "Australia" },
  { key: "AT", name: "Austria" },
  { key: "AZ", name: "Azerbaijan" },
  { key: "BS", name: "Bahamas" },
  { key: "BH", name: "Bahrain" },
  { key: "BD", name: "Bangladesh" },
  { key: "BB", name: "Barbados" },
  { key: "BY", name: "Belarus" },
  { key: "BE", name: "Belgium" },
  { key: "BZ", name: "Belize" },
  { key: "BJ", name: "Benin" },
  { key: "BM", name: "Bermuda" },
  { key: "BT", name: "Bhutan" },
  { key: "BO", name: "Bolivia" },
  { key: "BA", name: "Bosnia And Herzegovina" },
  { key: "BW", name: "Botswana" },
  { key: "BV", name: "Bouvet Island" },
  { key: "BR", name: "Brazil" },
  { key: "IO", name: "British Indian Ocean Territory" },
  { key: "BN", name: "Brunei Darussalam" },
  { key: "BG", name: "Bulgaria" },
  { key: "BF", name: "Burkina Faso" },
  { key: "BI", name: "Burundi" },
  { key: "KH", name: "Cambodia" },
  { key: "CM", name: "Cameroon" },
  { key: "CA", name: "Canada" },
  { key: "CV", name: "Cape Verde" },
  { key: "KY", name: "Cayman Islands" },
  { key: "CF", name: "Central African Republic" },
  { key: "TD", name: "Chad" },
  { key: "CL", name: "Chile" },
  { key: "CN", name: "China" },
  { key: "CX", name: "Christmas Island" },
  { key: "CC", name: "Cocos (keeling) Islands" },
  { key: "CO", name: "Colombia" },
  { key: "KM", name: "Comoros" },
  { key: "CG", name: "Congo" },
  { key: "CD", name: "Congo, The Democratic Republic Of The" },
  { key: "CK", name: "Cook Islands" },
  { key: "CR", name: "Costa Rica" },
  { key: "CI", name: "Cote D'ivoire" },
  { key: "HR", name: "Croatia" },
  { key: "CU", name: "Cuba" },
  { key: "CY", name: "Cyprus" },
  { key: "CZ", name: "Czech Republic" },
  { key: "DK", name: "Denmark" },
  { key: "DJ", name: "Djibouti" },
  { key: "DM", name: "Dominica" },
  { key: "DO", name: "Dominican Republic" },
  { key: "TP", name: "East Timor" },
  { key: "EC", name: "Ecuador" },
  { key: "EG", name: "Egypt" },
  { key: "SV", name: "El Salvador" },
  { key: "GQ", name: "Equatorial Guinea" },
  { key: "ER", name: "Eritrea" },
  { key: "EE", name: "Estonia" },
  { key: "ET", name: "Ethiopia" },
  { key: "FK", name: "Falkland Islands (malvinas)" },
  { key: "FO", name: "Faroe Islands" },
  { key: "FJ", name: "Fiji" },
  { key: "FI", name: "Finland" },
  { key: "FR", name: "France" },
  { key: "GF", name: "French Guiana" },
  { key: "PF", name: "French Polynesia" },
  { key: "TF", name: "French Southern Territories" },
  { key: "GA", name: "Gabon" },
  { key: "GM", name: "Gambia" },
  { key: "GE", name: "Georgia" },
  { key: "DE", name: "Germany" },
  { key: "GH", name: "Ghana" },
  { key: "GI", name: "Gibraltar" },
  { key: "GR", name: "Greece" },
  { key: "GL", name: "Greenland" },
  { key: "GD", name: "Grenada" },
  { key: "GP", name: "Guadeloupe" },
  { key: "GU", name: "Guam" },
  { key: "GT", name: "Guatemala" },
  { key: "GN", name: "Guinea" },
  { key: "GW", name: "Guinea-bissau" },
  { key: "GY", name: "Guyana" },
  { key: "HT", name: "Haiti" },
  { key: "HM", name: "Heard Island And Mcdonald Islands" },
  { key: "VA", name: "Holy See (vatican City State)" },
  { key: "HN", name: "Honduras" },
  { key: "HK", name: "Hong Kong" },
  { key: "HU", name: "Hungary" },
  { key: "IS", name: "Iceland" },
  { key: "IN", name: "India" },
  { key: "ID", name: "Indonesia" },
  { key: "IR", name: "Iran, Islamic Republic Of" },
  { key: "IQ", name: "Iraq" },
  { key: "IE", name: "Ireland" },
  { key: "IL", name: "Israel" },
  { key: "IT", name: "Italy" },
  { key: "JM", name: "Jamaica" },
  { key: "JP", name: "Japan" },
  { key: "JO", name: "Jordan" },
  { key: "KZ", name: "Kazakstan" },
  { key: "KE", name: "Kenya" },
  { key: "KI", name: "Kiribati" },
  { key: "KP", name: "Korea, Democratic People's Republic Of" },
  { key: "KR", name: "Korea, Republic Of" },
  { key: "KV", name: "Kosovo" },
  { key: "KW", name: "Kuwait" },
  { key: "KG", name: "Kyrgyzstan" },
  { key: "LA", name: "Lao People's Democratic Republic" },
  { key: "LV", name: "Latvia" },
  { key: "LB", name: "Lebanon" },
  { key: "LS", name: "Lesotho" },
  { key: "LR", name: "Liberia" },
  { key: "LY", name: "Libyan Arab Jamahiriya" },
  { key: "LI", name: "Liechtenstein" },
  { key: "LT", name: "Lithuania" },
  { key: "LU", name: "Luxembourg" },
  { key: "MO", name: "Macau" },
  { key: "MK", name: "Macedonia, The Former Yugoslav Republic Of" },
  { key: "MG", name: "Madagascar" },
  { key: "MW", name: "Malawi" },
  { key: "MY", name: "Malaysia" },
  { key: "MV", name: "Maldives" },
  { key: "ML", name: "Mali" },
  { key: "MT", name: "Malta" },
  { key: "MH", name: "Marshall Islands" },
  { key: "MQ", name: "Martinique" },
  { key: "MR", name: "Mauritania" },
  { key: "MU", name: "Mauritius" },
  { key: "YT", name: "Mayotte" },
  { key: "MX", name: "Mexico" },
  { key: "FM", name: "Micronesia, Federated States Of" },
  { key: "MD", name: "Moldova, Republic Of" },
  { key: "MC", name: "Monaco" },
  { key: "MN", name: "Mongolia" },
  { key: "MS", name: "Montserrat" },
  { key: "ME", name: "Montenegro" },
  { key: "MA", name: "Morocco" },
  { key: "MZ", name: "Mozambique" },
  { key: "MM", name: "Myanmar" },
  { key: "NA", name: "Namibia" },
  { key: "NR", name: "Nauru" },
  { key: "NP", name: "Nepal" },
  { key: "NL", name: "Netherlands" },
  { key: "AN", name: "Netherlands Antilles" },
  { key: "NC", name: "New Caledonia" },
  { key: "NZ", name: "New Zealand" },
  { key: "NI", name: "Nicaragua" },
  { key: "NE", name: "Niger" },
  { key: "NG", name: "Nigeria" },
  { key: "NU", name: "Niue" },
  { key: "NF", name: "Norfolk Island" },
  { key: "MP", name: "Northern Mariana Islands" },
  { key: "NO", name: "Norway" },
  { key: "OM", name: "Oman" },
  { key: "PK", name: "Pakistan" },
  { key: "PW", name: "Palau" },
  { key: "PS", name: "Palestinian Territory, Occupied" },
  { key: "PA", name: "Panama" },
  { key: "PG", name: "Papua New Guinea" },
  { key: "PY", name: "Paraguay" },
  { key: "PE", name: "Peru" },
  { key: "PH", name: "Philippines" },
  { key: "PN", name: "Pitcairn" },
  { key: "PL", name: "Poland" },
  { key: "PT", name: "Portugal" },
  { key: "PR", name: "Puerto Rico" },
  { key: "QA", name: "Qatar" },
  { key: "RE", name: "Reunion" },
  { key: "RO", name: "Romania" },
  { key: "RU", name: "Russian Federation" },
  { key: "RW", name: "Rwanda" },
  { key: "SH", name: "Saint Helena" },
  { key: "KN", name: "Saint Kitts And Nevis" },
  { key: "LC", name: "Saint Lucia" },
  { key: "PM", name: "Saint Pierre And Miquelon" },
  { key: "VC", name: "Saint Vincent And The Grenadines" },
  { key: "WS", name: "Samoa" },
  { key: "SM", name: "San Marino" },
  { key: "ST", name: "Sao Tome And Principe" },
  { key: "SA", name: "Saudi Arabia" },
  { key: "SN", name: "Senegal" },
  { key: "RS", name: "Serbia" },
  { key: "SC", name: "Seychelles" },
  { key: "SL", name: "Sierra Leone" },
  { key: "SG", name: "Singapore" },
  { key: "SK", name: "Slovakia" },
  { key: "SI", name: "Slovenia" },
  { key: "SB", name: "Solomon Islands" },
  { key: "SO", name: "Somalia" },
  { key: "ZA", name: "South Africa" },
  { key: "GS", name: "South Georgia And The South Sandwich Islands" },
  { key: "ES", name: "Spain" },
  { key: "LK", name: "Sri Lanka" },
  { key: "SD", name: "Sudan" },
  { key: "SR", name: "Suriname" },
  { key: "SJ", name: "Svalbard And Jan Mayen" },
  { key: "SZ", name: "Swaziland" },
  { key: "SE", name: "Sweden" },
  { key: "CH", name: "Switzerland" },
  { key: "SY", name: "Syrian Arab Republic" },
  { key: "TW", name: "Taiwan, Province Of China" },
  { key: "TJ", name: "Tajikistan" },
  { key: "TZ", name: "Tanzania, United Republic Of" },
  { key: "TH", name: "Thailand" },
  { key: "TG", name: "Togo" },
  { key: "TK", name: "Tokelau" },
  { key: "TO", name: "Tonga" },
  { key: "TT", name: "Trinidad And Tobago" },
  { key: "TN", name: "Tunisia" },
  { key: "TR", name: "Turkey" },
  { key: "TM", name: "Turkmenistan" },
  { key: "TC", name: "Turks And Caicos Islands" },
  { key: "TV", name: "Tuvalu" },
  { key: "UG", name: "Uganda" },
  { key: "UA", name: "Ukraine" },
  { key: "AE", name: "United Arab Emirates" },
  { key: "GB", name: "UK" },
  { key: "US", name: "United States" },
  { key: "UM", name: "United States Minor Outlying Islands" },
  { key: "UY", name: "Uruguay" },
  { key: "UZ", name: "Uzbekistan" },
  { key: "VU", name: "Vanuatu" },
  { key: "VE", name: "Venezuela" },
  { key: "VN", name: "Viet Nam" },
  { key: "VG", name: "Virgin Islands, British" },
  { key: "VI", name: "Virgin Islands, U.s." },
  { key: "WF", name: "Wallis And Futuna" },
  { key: "EH", name: "Western Sahara" },
  { key: "YE", name: "Yemen" },
  { key: "ZM", name: "Zambia" },
  { key: "ZW", name: "Zimbabwe" },
];

export default countries;
