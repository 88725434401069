import errorValidator from "@/api/errorValidator";
import useCorkscrewApi from "@/hooks/useCorkscrewApi";
import { decode, id } from "@/utils/gid";
import routes from "@/utils/routes";
import theme from "@/utils/theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ViewList from "@mui/icons-material/ViewList";
import ViewModule from "@mui/icons-material/ViewModule";
import {
  Alert,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TFunction } from "i18next";
import { useTranslation } from "next-i18next";
import React, { useEffect, useState } from "react";
import { MyAccountLink } from "../atoms";
import { OrderProgress } from "../molecules/OrderProcess/OrderProgress";
import OrderViewItem, {
  findStatusColour,
} from "../molecules/OrderProcess/OrderViewItem";
import {
  OrderProgressStep,
  OrderProgressStepType,
} from "../molecules/OrderProcess/types";

import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import Head from "next/head";
import { default as NextLink } from "next/link";
import { NextRouter, useRouter } from "next/router";
import { LoadingView } from "../atoms";
import Metafield from "../molecules/Metafield";
import { useUser } from "@/contexts/userContext";

const statusToStep = (order: any): OrderProgressStepType => {
  if (order?.shippingStatus?.slug) {
    return OrderProgressStepType.Shipping;
  }

  if (order?.qaStatus?.slug) {
    return OrderProgressStepType.QualityControl;
  }

  if (order?.manufacturingStatus?.slug?.includes("cutting")) {
    return OrderProgressStepType.Cutting;
  }

  if (order?.manufacturingStatus?.slug?.includes("printing")) {
    return OrderProgressStepType.Printing;
  }

  // fallback to Printing as the steps aren't fully supported in Glide yet.
  if (order?.manufacturingStatus) {
    return OrderProgressStepType.Printing;
  }

  if (order?.proofStatus?.slug === "sent") {
    return OrderProgressStepType.FeedbackRequired;
  }

  if (order?.proofStatus?.slug) {
    return OrderProgressStepType.DesignInProgress;
  }

  if (order?.orderStatus?.slug) {
    return OrderProgressStepType.OrderPlaced;
  }

  return OrderProgressStepType.DesignInProgress;
};

const getSteps = (
  t: TFunction<"ProgressSteps">,
  activeStep: OrderProgressStepType
) => {
  const steps: Array<[OrderProgressStepType, boolean, string]> = [
    [OrderProgressStepType.OrderPlaced, false, "orderPlaced"],
    [OrderProgressStepType.DesignInProgress, true, "designInProgress"],
    [OrderProgressStepType.FeedbackRequired, true, "feedbackRequired"],
    [OrderProgressStepType.Printing, true, "printing"],
    [OrderProgressStepType.Cutting, true, "cutting"],
    [OrderProgressStepType.QualityControl, true, "qualityControl"],
    [OrderProgressStepType.Shipping, true, "shipping"],
  ];

  return steps.map(([step, modal, icon]) => {
    const basic: OrderProgressStep = {
      key: step.toString(),
      title: t(`${step}.title`),
      description: t(`${step}.description`),
      icon,
      active: step === activeStep,
    };

    if (modal) {
      return {
        ...basic,
        modal: {
          title: t(`${step}.modal.title`),
          description: t(`${step}.modal.description`),
        },
      };
    }

    return basic;
  });
};

const OrderViewTemplate: React.FC = () => {
  const {
    state: { shopifyOrderUrl },
  } = useUser();

  const corkscrewApi = useCorkscrewApi();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const { allMaterials } = useFetchMaterials();
  const allMaterials = [];

  const [isListView, setIsListView] = useState(false);
  const [order, setOrder] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState();
  const { t } = useTranslation("OrderView", {});
  const { t: tSteps } = useTranslation("ProgressSteps", {});
  const router: NextRouter = useRouter();
  const orderNumber = router.query.id;

  useEffect(() => {
    if (!corkscrewApi || !orderNumber) {
      return;
    }

    corkscrewApi
      .fetchOrder(orderNumber.toString() || "")
      .then((order) => {
        if (!order) {
          return;
        }
        setOrder(order);
        setIsLoading(false);
      })
      .catch((err) => {
        errorValidator(err);
        setErrorMsg(err.message);
        setIsLoading(false);
      });
  }, [corkscrewApi, orderNumber]);

  if (isLoading) {
    return <LoadingView suffix="Order" />;
  }

  if (errorMsg) {
    return (
      <Alert severity="error">
        <Stack>
          <Typography>{t("orderNotFound")}</Typography>
          <Link
            component={NextLink}
            underline="hover"
            href={`${routes.orders}`}
          >
            Back to orders
          </Link>
        </Stack>
      </Alert>
    );
  }

  return (
    <>
      <Head>
        <title>Order summary: {order.orderNumber}</title>
      </Head>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={4} direction="column">
          {/* Breadcrumbs */}
          <Grid xs={12}>
            {" "}
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                component={NextLink}
                underline="hover"
                href={shopifyOrderUrl || routes.orders}
                sx={{ color: theme.colors.greyDark }}
              >
                My Account
              </Link>
              <Link
                component={NextLink}
                underline="hover"
                sx={{ color: theme.colors.greyDark }}
                href={"/orders"}
              >
                Orders
              </Link>
              <Link
                component={NextLink}
                underline="hover"
                href={`/orders/${decode(order.id).id}`}
                sx={{ color: theme.colors.greyDark }}
              >
                {order.orderNumber}
              </Link>
            </Breadcrumbs>
          </Grid>

          {/* MainContainer */}
          <Grid xs={12}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={4} sx={{ flexGrow: 1 }}>
                <Grid xs={12} md={4} order={{ xs: 1, md: 2 }}>
                  <OrderProgress
                    steps={getSteps(tSteps, statusToStep(order))}
                  />
                </Grid>
                <Grid xs={12} md={8} order={{ xs: 1, md: 1 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: "20px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Link
                              component={NextLink}
                              underline="hover"
                              href={`/orders`}
                              sx={{
                                color: theme.colors.greyDark,
                              }}
                            >
                              <ArrowBackIcon />
                            </Link>
                            <Typography
                              sx={{
                                fontSize: { xs: "20px" },
                              }}
                            >
                              {t("title")}
                              {": "}
                              <Typography
                                sx={{
                                  fontSize: "inherit",
                                  color: theme.colors.coolBlue,
                                }}
                                component="span"
                              >
                                {order.orderNumber}
                              </Typography>
                            </Typography>
                          </Box>
                          <IconButton
                            aria-label="settings"
                            onClick={() => setIsListView(!isListView)}
                            // disabled
                          >
                            {!isListView ? <ViewList /> : <ViewModule />}
                          </IconButton>
                        </Box>
                      </Grid>
                      <Grid xs={12}>
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid
                            container
                            spacing={2}
                            // sx={{ gridTemplateColumns: "repeat(3, 1fr)" }}
                          >
                            {order.lineItems.map((lineItem, idx) => {
                              if (
                                lineItem.proofs?.length === 0 ||
                                lineItem.proofStatus === null
                              ) {
                                return (
                                  <Grid
                                    key={lineItem.id}
                                    xs={isListView ? 12 : 12}
                                    sm={isListView ? 12 : 6}
                                    md={isListView ? 12 : 4}
                                  >
                                    <Card>
                                      <Box sx={{ flexGrow: 1 }}>
                                        <Grid
                                          container
                                          direction={
                                            isListView && !isMobile
                                              ? "row"
                                              : "column"
                                          }
                                          spacing={2}
                                        >
                                          <Grid
                                            sx={{
                                              position: "relative",
                                              width: isListView
                                                ? isMobile
                                                  ? "100%"
                                                  : "200px"
                                                : "auto",
                                              flexShrink: 0,
                                              maxWidth: isListView
                                                ? isMobile
                                                  ? "100%"
                                                  : "200px"
                                                : "auto",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                width: "100%",
                                                height: "200px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                bgcolor: "grey.200",
                                              }}
                                            >
                                              <BrokenImageIcon color="error" />
                                              <Typography
                                                variant="body2"
                                                color="error"
                                                sx={{ marginLeft: 1 }}
                                              >
                                                No proof image
                                              </Typography>
                                            </Box>
                                          </Grid>
                                          <Grid xs>
                                            <CardContent>
                                              <Typography
                                                variant="h5"
                                                textAlign={
                                                  isListView ? "left" : "center"
                                                }
                                              >
                                                {lineItem.name}
                                              </Typography>
                                              <Box
                                                sx={{
                                                  padding: 1,
                                                  textAlign: "center",
                                                  fontSize: "14px",
                                                  marginTop: "14px",
                                                  ...findStatusColour(
                                                    "",
                                                    theme
                                                  ),
                                                }}
                                              >
                                                Contact support
                                              </Box>
                                            </CardContent>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Card>
                                  </Grid>
                                );
                              }

                              return (
                                <Grid
                                  key={lineItem.id}
                                  xs={isListView ? 12 : 12}
                                  sm={isListView ? 12 : 6}
                                  md={isListView ? 12 : 4}
                                >
                                  <OrderViewItem
                                    name={lineItem.name}
                                    isListView={isListView}
                                    orderId={id(order.id)}
                                    myOrdersPagePath={routes.orders}
                                    orderNumber={order.name}
                                    status={lineItem.proofStatus}
                                    proofImageUrl={
                                      lineItem.proofs
                                        ?.at(0)
                                        ?.files?.find((f) =>
                                          f.purposes
                                            .map((p) => p.id)
                                            .includes(
                                              "gid://glide/FilePurpose/fp-clv95gh1l0006olmewfy1nmof"
                                            )
                                        )?.fullSizeUrl
                                    }
                                    fullSizeImageUrl={
                                      lineItem.proofs
                                        ?.at(0)
                                        ?.files?.find((f) =>
                                          f.purposes
                                            .map((p) => p.id)
                                            .includes(
                                              "gid://glide/FilePurpose/fp-clv95gh1l0006olmewfy1nmof"
                                            )
                                        )?.fullSizeUrl
                                    }
                                    customerArtworkImageUrl={
                                      lineItem.customerArtworkImageUrl
                                    }
                                    proofId={id(lineItem.proofs?.at(0)?.id)}
                                    meta={lineItem.meta}
                                    modifiers={[
                                      {
                                        title: "Quantity",
                                        value: lineItem.quantity,
                                      },
                                    ].concat(
                                      lineItem.fields
                                        .filter((f) => f.value)
                                        .filter((f) => {
                                          // TODO: We should filter the fields in the query instead
                                          return [
                                            "gid://glide/CoreMetafieldDefinitionType/StickerSize",
                                            "gid://glide/CoreMetafieldDefinitionType/LabelSize",
                                            "gid://glide/CoreMetafieldDefinitionType/Shape",
                                          ].includes(
                                            f.config.fieldDefinitionType.id
                                          );
                                        })
                                        .map((f) => {
                                          return {
                                            title:
                                              f.config.fieldDefinitionType.name,
                                            value: (
                                              <Metafield
                                                defId={
                                                  f.config.fieldDefinitionType
                                                    .id
                                                }
                                                value={f.value}
                                              />
                                            ),
                                          };
                                        })
                                    )}
                                    instructions={lineItem.instructions}
                                    productName={lineItem.product.name}
                                    designNote={
                                      lineItem?.proofs?.at(0).designComments
                                    }
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default OrderViewTemplate;
