import { useEffect, useState } from "react";
import { MaterialType } from "@/types";
import useCorkscrewApi from "./useCorkscrewApi";
import errorValidator from "@/api/errorValidator";

export default function useFetchMaterials() {
  const [allMaterials, setAllMaterials] = useState<{
    data: MaterialType[];
  }>({ data: [] });

  const corkscrewApi = useCorkscrewApi();

  useEffect(() => {
    if (!corkscrewApi) return;

    corkscrewApi
      .fetchMaterials()
      .then((materials) => {
        setAllMaterials(materials);
      })
      .catch((err) => errorValidator(err));
  }, []);

  return { allMaterials };
}
