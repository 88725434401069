import {
  Box,
  Breadcrumbs,
  Link,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { OrderListEdge, PageInfo } from "@/api/corkcrew";
import errorValidator from "@/api/errorValidator";
import { useUser } from "@/contexts/userContext";
import useCorkscrewApi from "@/hooks/useCorkscrewApi";
import routes from "@/utils/routes";
import theme from "@/utils/theme";
import { default as NextLink } from "next/link";
import { MyAccountLink, OrdersListSkeleton } from "../atoms";
import { OrderListTable } from "../molecules";

const defaultItemPerPage = 10;

const OrdersListTemplate: React.FC = () => {
  const {
    state: { canDownloadInvoice },
  } = useUser();
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageInfo, setPageInfo] = useState<PageInfo>();

  const [orders, setOrders] = useState<OrderListEdge[]>([]);
  const [isFetchingOrders, setIsFetchingOrders] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemPerPage);

  const corkscrewApi = useCorkscrewApi();

  const handleFetchOrders = (
    perPage: number = itemsPerPage,
    page: number,
    sort: string,
    before?: string,
    after?: string
  ) => {
    corkscrewApi
      .fetchOrders(perPage, page, sort, before, after)
      .then((fetchedOrders) => {
        const sortedOrders = (fetchedOrders?.edges || []).sort((a, b) =>
          b.node.id.localeCompare(a.node.id)
        );

        setOrders(sortedOrders);
        setPageInfo(fetchedOrders.pageInfo);
        setIsFetchingOrders(false);
        setTotalCount(fetchedOrders.totalCount);
      })
      .catch((err) => errorValidator(err));
  };

  useEffect(() => {
    if (!corkscrewApi) {
      return;
    }

    handleFetchOrders(itemsPerPage, page, "desc");
  }, [corkscrewApi, itemsPerPage]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    // TODO: don't love this.
    if (newPage > page) {
      // next action
      handleFetchOrders(
        itemsPerPage,
        newPage,
        "desc",
        undefined,
        pageInfo?.endCursor
      );
      setPage(newPage);
      return;
    }

    // back action
    handleFetchOrders(
      itemsPerPage,
      newPage,
      "desc",
      pageInfo?.startCursor,
      undefined
    );
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        paddingBottom: 6,
      }}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ marginTop: 2, marginBottom: 2 }}
      >
        <MyAccountLink />
        <Link
          component={NextLink}
          underline="hover"
          color="text.primary"
          href={routes.orders}
        >
          Orders
        </Link>
      </Breadcrumbs>

      <Box
        sx={{
          marginTop: { xs: 0, md: 4 },
          marginBottom: { xs: 0, md: 4 },
          [theme.breakpoints.down("xs")]: {
            paddingLeft: 2,
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.typography.pxToRem(50),
            [theme.breakpoints.down("xs")]: {
              fontSize: theme.typography.pxToRem(35),
            },
          }}
        >
          {/* {datoCmsAccountPage.title} */}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            marginTop: 2,
            fontSize: theme.typography.pxToRem(26),
            fontWeight: theme.typography.fontWeightRegular,
            [theme.breakpoints.up("sm")]: {
              display: "none",
            },
          }}
        >
          {/* {datoCmsAccountPage.latestOrders} */}
        </Typography>
      </Box>
      <Box>
        {isFetchingOrders ? (
          <OrdersListSkeleton />
        ) : (
          <>
            <OrderListTable
              ordersPagePath={routes.orders}
              orders={orders}
              canDownloadInvoice={canDownloadInvoice}
            />
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={itemsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default OrdersListTemplate;
