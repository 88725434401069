import React, { useEffect, useState } from "react";
import { default as NextLink } from "next/link";
import {
  Breadcrumbs,
  ImageList,
  Link,
  Typography,
  Box,
  Fade,
  Container,
} from "@mui/material";
import useCorkscrewApi from "@/hooks/useCorkscrewApi";
import useFetchMaterials from "@/hooks/useFetchMaterials";
import { ImageListLoader, Loader } from "../atoms/Loaders";
import { DesignModal, MyAccountLink } from "../atoms";
import { DesignItem } from "../organisms";
import errorValidator from "@/api/errorValidator";
import { PublicApprovedArtwork } from "@/types";

const defaultItemsPerPage = 25;

const DesignsTemplate: React.FC = () => {
  const [designs, setDesigns] = useState<PublicApprovedArtwork[]>([]);
  const [isFetchingOrders, setIsFetchingOrders] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedDesign, setSelectedDesign] =
    useState<PublicApprovedArtwork | null>(null);

  const { allMaterials } = useFetchMaterials();

  const corkscrewApi = useCorkscrewApi();

  const handleOpenImageModal = (design: PublicApprovedArtwork) => {
    setSelectedDesign(design);
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
    setSelectedDesign(null);
  };

  useEffect(() => {
    if (!corkscrewApi) {
      return;
    }

    window.addEventListener("scroll", infiniteScroll);

    corkscrewApi
      .fetchDesigns(itemsPerPage, page, "desc")
      .then((resp) => {
        setDesigns((prev) => {
          return page === 0 ? resp.data : prev.concat(resp.data);
        });
        setIsFetchingOrders(false);
        setIsLoadingMore(false);
        setShowMore(resp.meta?.pagination?.hasMore || false);
      })
      .catch((err) => errorValidator(err));
  }, [itemsPerPage, page]);

  const infiniteScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      setIsLoadingMore(true);
      setItemsPerPage(4);
      setPage((prev) => prev + 1);
    }
  };

  return (
    <Box>
      <DesignModal
        open={openImageModal}
        onClose={handleCloseImageModal}
        selectedDesign={selectedDesign}
      />
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ marginTop: 2, marginBottom: 2 }}
      >
        <MyAccountLink />
        <Link
          component={NextLink}
          underline="hover"
          color="text.primary"
          href={"../"}
        >
          Designs
        </Link>
      </Breadcrumbs>

      <Typography variant="h4">
        My Designs
        {isFetchingOrders ? <ImageListLoader /> : null}
        <Fade in={!isFetchingOrders} unmountOnExit>
          <Box>
            <Box sx={{ display: { xs: "inherit", md: "none" } }}>
              <ImageList cols={2} gap={6}>
                {designs.map((design) => {
                  return (
                    <DesignItem
                      key={design.proofId}
                      design={design}
                      allMaterials={allMaterials}
                      onClick={(design) => handleOpenImageModal(design)}
                    />
                  );
                })}
              </ImageList>
            </Box>
            <Box sx={{ display: { xs: "none", md: "inherit" } }}>
              <ImageList cols={5} gap={12}>
                {designs.map((design) => {
                  return (
                    <DesignItem
                      key={design.proofId}
                      design={design}
                      allMaterials={allMaterials}
                      onClick={(design) => handleOpenImageModal(design)}
                    />
                  );
                })}
              </ImageList>
            </Box>
          </Box>
        </Fade>
      </Typography>

      {showMore && isLoadingMore ? <Loader /> : null}
    </Box>
  );
};

export default DesignsTemplate;
