import { TFunction } from "i18next";
import * as yup from "yup";

const phoneNumberRegex = /^[+\d](?:.*\d)?$/;

export const getValidation = (t: TFunction<"FormErrors">) => {
  return yup.object().shape({
    phone: yup
      .string()
      .trim()
      .matches(phoneNumberRegex, t("onlyNumbers") || "")
      .min(8, t("phone") || "")
      .required(t("required") || ""),
    zip: yup
      .string()
      .trim()
      .min(4, t("postcode") || "")
      .required(t("required") || ""),
    city: yup
      .string()
      .trim()
      .required(t("required") || ""),
    line1: yup
      .string()
      .trim()
      .required(t("required") || ""),
    companyName: yup.string().trim().optional(),
    lastName: yup
      .string()
      .trim()
      .required(t("required") || ""),
    firstName: yup
      .string()
      .trim()
      .required(t("required") || ""),
    countryCode: yup
      .string()
      .trim()
      .length(2)
      .required(t("required") || ""),
  });
};
