export enum OrderProgressStepType {
  OrderPlaced = "OrderPlaced",
  DesignInProgress = "DesignInProgress",
  FeedbackRequired = "FeedbackRequired",
  Printing = "Printing",
  Cutting = "Cutting",
  QualityControl = "QualityControl",
  Shipping = "Shipping",
}

export interface OrderProgressStep {
  key: string;
  active?: boolean;
  title: string;
  description: string;
  icon: string;

  modal?: {
    title: string;
    description: string;
  };
}
