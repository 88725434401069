import type { IPublicAddress } from "@kartdavid/corkscrew-types/public";
import { Breadcrumbs } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";

import { OrderListEdge } from "@/api/corkcrew";
import useCorkscrewApi from "@/hooks/useCorkscrewApi";
import useFetchMaterials from "@/hooks/useFetchMaterials";
import {
  EEditModalOrder,
  EModalActions,
  IPayment,
  IPreferenceFormData,
  PublicApprovedArtwork,
} from "@/types";
import routes from "@/utils/routes";
import { useTranslation } from "next-i18next";
import { NextRouter, useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { UserStateContext, useUser } from "../../contexts/userContext";
import { MyAccountLink } from "../atoms";
import AddAddressModal from "../molecules/Modals/AddAddressModal";
import { MyDetails } from "../organisms";

const ROUTE_DESIGNS = "./designs";

const userPaymentMethods: IPayment[] = [
  {
    visaEnding: 1242,
    expiryDate: "11/23",
    nameOnCard: "John Smith",
    default: true,
  },
  {
    visaEnding: 3233,
    expiryDate: "03/25",
    nameOnCard: "Alice Wonder",
    default: false,
  },
  {
    visaEnding: 1552,
    expiryDate: "12/24",
    nameOnCard: "John Smith",
    default: false,
  },
];

const initialFormData: IPreferenceFormData = {
  language: "",
  currency: "",
  units: "",
  textAlerts: false,
  whiteLabelPackaging: false,
  alertFrequency: "",
};

const itemsPerPage = 99999;

const AccountTemplate: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [orders, setOrders] = useState<OrderListEdge[]>([]);
  const [action, setAction] = useState<EModalActions>(EModalActions.none);
  const [userAddressesState, setUserAddressesState] = useState<
    IPublicAddress[]
  >([]);
  const [editModalOrder, setEditModalOrder] =
    React.useState<EEditModalOrder>(0);
  const [preferencesFormData, setPreferencesFormData] =
    React.useState<IPreferenceFormData>(initialFormData);
  const [addressToEdit, setAddressToEdit] = React.useState<IPublicAddress>(
    userAddressesState[0]
  );

  const user = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const { allMaterials } = useFetchMaterials();

  const [hasMoreOrders, setHasMoreOrders] = useState(false);
  const [isFetchingOrders, setIsFetchingOrders] = useState(true);
  const [haveDesigns, setHaveDesigns] = useState(false);
  const [designs, setDesigns] = useState<PublicApprovedArtwork[]>([]);
  const { t, ready } = useTranslation("AccountSummary");

  useEffect(() => {
    if (user?.state?.loggedIn) {
      return;
    }

    router.push(user?.state?.redirectTo || routes.login);
  }, [user?.state?.loggedIn, user?.state?.redirectTo]);

  const preferenceItems = !ready
    ? []
    : [
        { id: t("preferenceOptions.language"), value: "English", edit: true },
        { id: t("preferenceOptions.currency"), value: "GBP", edit: true },
        { id: t("preferenceOptions.units"), value: "Metrics cm", edit: true },
        { id: t("preferenceOptions.textAlerts"), value: true, edit: false },
        {
          id: t("preferenceOptions.alertFrequency"),
          value: "Daily",
          edit: true,
        },
        {
          id: t("preferenceOptions.whiteLabelPackaging"),
          value: false,
          edit: false,
        },
      ];

  const router: NextRouter = useRouter();
  const userCtx = useContext(UserStateContext);
  const corkscrewApi = useCorkscrewApi();

  const handleCloseModal = () => setIsOpen(!isOpen);

  const handleEditButtonClick = (addr: IPublicAddress) => {
    setAction(EModalActions.editAddress);
    setIsOpen(true);
    setAddressToEdit(addr);
  };

  useEffect(() => {
    if (!corkscrewApi) {
      return;
    }

    const init = async () => {
      await Promise.all([
        corkscrewApi.fetchOrders(itemsPerPage, 0, "desc"),
        // corkscrewApi.fetchAddresses(),
        // corkscrewApi.fetchDesigns(itemsPerPage, 0, "desc"),
      ]).then((data) => {
        // TODO: Fix this setOrdersType
        const [fetchedOrders] = data as any;
        setOrders(fetchedOrders.data);
        // setUserAddressesState(fetchedAddresses);
        setHasMoreOrders(fetchedOrders.meta?.pagination?.hasMore || false);
        // setDesigns(fetchedDesigns.data);
        // setHaveDesigns(fetchedDesigns.data.length > 0);
        setIsFetchingOrders(false);
      });
    };

    init();
  }, [userCtx]);

  const handleEditOrder = (id: string) => {
    switch (id) {
      case "Language":
        return setEditModalOrder(EEditModalOrder.language);
      case "Currency":
        return setEditModalOrder(EEditModalOrder.currency);
      case "Units":
        return setEditModalOrder(EEditModalOrder.units);
      case "Alert frequency":
        return setEditModalOrder(EEditModalOrder.alertFrequency);
    }
  };

  const handleClick = (modalAction: EModalActions) => {
    handleCloseModal();
    switch (modalAction) {
      case EModalActions.changeDetails:
        return setAction(EModalActions.changeDetails);
      case EModalActions.changePassword:
        return setAction(EModalActions.changePassword);
      case EModalActions.addAddress:
        return setAction(EModalActions.addAddress);
      case EModalActions.addPaymentMethod:
        return setAction(EModalActions.addPaymentMethod);
      case EModalActions.preferenceLanguage:
        return setAction(EModalActions.preferenceLanguage);
    }
  };

  const userActions = {
    addAddress: function (
      address: IPublicAddress,
      handleActionChange: () => void
    ) {
      corkscrewApi
        ?.addAddress(address)
        .then(corkscrewApi.fetchAddresses)
        .then((addresses) => {
          setUserAddressesState(addresses);
          handleCloseModal();
          handleActionChange();
        })
        .catch(() => {
          handleActionChange();
          alert("could not add");
        });
    },
    removeAddress: function (address: IPublicAddress) {
      corkscrewApi
        ?.removeAddress(address.id)
        .then(corkscrewApi.fetchAddresses)
        .then((addresses) => {
          setUserAddressesState(addresses);
        });

      setUserAddressesState(
        userAddressesState.filter((addr) => addr.id !== address.id)
      );
    },
    editAddress: function (
      address: IPublicAddress,
      handleActionChange: () => void
    ) {
      corkscrewApi
        ?.editAddress(address)
        .then(corkscrewApi?.fetchAddresses)
        .then((addresses) => {
          setUserAddressesState(addresses);
          handleCloseModal();
          handleActionChange();
        })
        .catch(() => {
          handleActionChange();
          alert("could not edit");
        });
    },
    setDefaultAddress: function (address: IPublicAddress) {
      enqueueSnackbar("Setting Default Address...", { variant: "info" });
      Promise.all(
        userAddressesState.map((addr) => {
          return corkscrewApi?.editAddress({
            ...addr,
            isDefault: address.id === addr.id,
          });
        })
      )
        .then(corkscrewApi?.fetchAddresses)
        .then((addresses) => {
          enqueueSnackbar("Default Address Set", { variant: "success" });
          setUserAddressesState(addresses);
          handleCloseModal();
        })
        .catch(() => {
          enqueueSnackbar("Could not set as default", { variant: "error" });
        });
    },
  };

  return (
    <Fragment>
      {action === EModalActions.addAddress ? (
        <AddAddressModal
          handleClose={handleCloseModal}
          open={isOpen}
          handleSubmit={userActions.addAddress}
          edit={false}
        />
      ) : null}
      {action === EModalActions.editAddress ? (
        <AddAddressModal
          handleClose={handleCloseModal}
          open={isOpen}
          handleSubmit={userActions.editAddress}
          addressToEdit={addressToEdit}
          edit={true}
        />
      ) : null}
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ marginTop: 2, marginBottom: 1 }}
      >
        <MyAccountLink />
      </Breadcrumbs>
      <MyDetails
        {...{
          allMaterials,
          isFetchingOrders,
          hasMoreOrders,
          userAddressesState,
          designs,
          orders,
          userPaymentMethods,
          userActions,
          handleEditButtonClick,
          handleClick,
        }}
      />
    </Fragment>
  );
};

export default AccountTemplate;
