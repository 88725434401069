import type { IPublicAddress } from "@kartdavid/corkscrew-types/public";
import CircularProgress from "@mui/material/CircularProgress";
import { Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import React from "react";
import { useTranslation } from "react-i18next";

import countries from "@/utils/countries";
import { getValidation } from "./validation";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { ButtonWrapper, InputName } from "./styles";
import theme from "@/utils/theme";

interface IProps {
  handleClose: () => void;
  open: boolean;
  handleSubmit: (
    address: IPublicAddress,
    handleActionChange: () => void
  ) => void;
  addressToEdit?: IPublicAddress;
  edit: boolean;
}

interface IBlurred {
  country: boolean;
  firstName: boolean;
  lastName: boolean;
  address: boolean;
  city: boolean;
  zip: boolean;
  phone: boolean;
}

const initialBlurred: IBlurred = {
  country: false,
  firstName: false,
  lastName: false,
  address: false,
  city: false,
  zip: false,
  phone: false,
};

const initialFormData: IPublicAddress = {
  id: "",
  firstName: "",
  lastName: "",
  companyName: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  countryCode: "",
  zip: "",
  phone: "",
  email: "",
  isDefault: false,
};

const Section = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const InputWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const TextInput = styled(TextField)(({ theme }) => ({
  width: "100%",
  fontSize: "14px",
}));

const AddAddressModal: React.FunctionComponent<IProps> = ({
  handleClose,
  open,
  handleSubmit,
  addressToEdit,
  edit,
}: IProps) => {
  const getInitialForm =
    edit && addressToEdit ? addressToEdit : initialFormData;
  const { t } = useTranslation("Address");
  const { t: tFormErrors } = useTranslation("FormErrors");
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [errorFormData, setErrorFormData] = React.useState(initialFormData);
  const [formData, setFormData] =
    React.useState<IPublicAddress>(getInitialForm);
  const schema = getValidation(tFormErrors);
  const [isBlurred, setIsBlurred] = React.useState<IBlurred>(initialBlurred);
  const [actionState, setActionState] = React.useState<{
    isLoading: boolean;
  }>({
    isLoading: false,
  });
  const renderButtonName = edit ? t("Edit.button") : t("Add.button");

  const updateErrorData = (errors: any) => {
    errors.forEach((error: any) => {
      setErrorFormData(() => ({
        ...initialFormData,
        [error.path]: error.message,
      }));
    });
  };

  const handleEditSubmit = () => {
    setActionState({ isLoading: false });
    setIsBlurred(initialBlurred);
  };
  const handleAddSubmit = () => {
    setActionState({ isLoading: false });
    setFormData(initialFormData);
    setIsBlurred(initialBlurred);
  };

  React.useEffect(() => {
    if (formData.countryCode.length === 0) {
      return;
    }
    schema
      .validate(formData, { abortEarly: false })
      .then(() => {
        setIsDisabled(false);
        setErrorFormData(initialFormData);
      })
      .catch((err) => {
        setIsDisabled(true);
        updateErrorData(err.inner);
        return;
      });
  }, [formData]);

  React.useEffect(() => {
    if (addressToEdit) {
      setFormData(addressToEdit);
    }
  }, [addressToEdit]);

  return (
    <Dialog open={open} onClose={handleClose} scroll={"body"} fullWidth>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: { xs: "100%", md: "600px" },
          padding: theme.spacing(2, 2, 0, 2),
        }}
      >
        <CancelIcon
          onClick={handleClose}
          style={{
            width: 20,
            height: 20,
            fill: theme.colors.grey,
            cursor: "pointer",
          }}
        />
      </Box>
      <DialogTitle>
        <Typography
          sx={{
            fontSize: theme.typography.pxToRem(26),
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(-2),
          }}
        >
          {edit ? t("Edit.title") : t("Add.title")}
        </Typography>
      </DialogTitle>
      <DialogContent dividers={false}>
        <div>
          <form autoComplete="on">
            <Section
              sx={{
                display: {
                  xs: "block",
                  md: "flex",
                  justifyContent: "space-between",
                },
              }}
            >
              <Box sx={{ width: { xs: "100%", md: "48%" } }}>
                <InputName>{t("firstName")}</InputName>
                <InputWrapper>
                  <TextInput
                    placeholder={t("firstNamePlaceholder") || ""}
                    autoComplete="given-name"
                    value={formData.firstName}
                    variant="outlined"
                    required
                    onBlur={() =>
                      setIsBlurred({
                        ...isBlurred,
                        firstName: true,
                      })
                    }
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        firstName: event.target.value as string,
                      })
                    }
                    error={
                      isBlurred.firstName && errorFormData.firstName.length > 0
                    }
                    helperText={isBlurred.firstName && errorFormData.firstName}
                  />
                </InputWrapper>
              </Box>
              <Box sx={{ width: { xs: "100%", md: "50%" } }}>
                <InputName>{t("lastName")}</InputName>
                <InputWrapper>
                  <TextInput
                    placeholder={t("lastNamePlaceholder") || ""}
                    value={formData.lastName}
                    variant="outlined"
                    autoComplete="family-name"
                    required
                    onBlur={() =>
                      setIsBlurred({
                        ...isBlurred,
                        lastName: true,
                      })
                    }
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        lastName: event.target.value as string,
                      })
                    }
                    error={
                      isBlurred.lastName && errorFormData.lastName.length > 0
                    }
                    helperText={isBlurred.lastName && errorFormData.lastName}
                  />
                </InputWrapper>
              </Box>
            </Section>
            <Section
              sx={{
                display: {
                  xs: "block",
                  md: "flex",
                  justifyContent: "space-between",
                },
              }}
            >
              <Box sx={{ width: { xs: "100%", md: "48%" } }}>
                <InputName>{t("postCode")}</InputName>
                <InputWrapper>
                  <TextField
                    fullWidth
                    autoComplete="postal-code"
                    placeholder={t("postCodePlaceholder") || ""}
                    value={formData.zip}
                    variant="outlined"
                    onBlur={() =>
                      setIsBlurred({
                        ...isBlurred,
                        zip: true,
                      })
                    }
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        zip: event.target.value as string,
                      })
                    }
                    required
                    error={isBlurred.zip && errorFormData.zip.length > 0}
                    helperText={isBlurred.zip && errorFormData.zip}
                  />
                </InputWrapper>
              </Box>
              <Box sx={{ width: { xs: "100%", md: "48%" } }}>
                <InputName>{t("phoneNumber")}</InputName>
                <InputWrapper>
                  <TextField
                    fullWidth
                    autoComplete="tel"
                    placeholder={t("phoneNumberPlaceholder") || ""}
                    value={formData.phone}
                    variant="outlined"
                    onBlur={() =>
                      setIsBlurred({
                        ...isBlurred,
                        phone: true,
                      })
                    }
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        phone: event.target.value as string,
                      })
                    }
                    required
                    error={isBlurred.phone && errorFormData.phone.length > 0}
                    helperText={isBlurred.phone && errorFormData.phone}
                  />
                </InputWrapper>
              </Box>
            </Section>
            <Section>
              <InputName>{t("country")}</InputName>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <FormControl
                  variant="outlined"
                  sx={{
                    margin: theme.spacing(1, 0),
                    width: "100%",
                  }}
                  error={errorFormData.countryCode.length > 0}
                >
                  <Select
                    MenuProps={{ disablePortal: true }}
                    value={formData.countryCode}
                    autoComplete="country"
                    required
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        countryCode: event.target.value as string,
                      })
                    }
                    variant="outlined"
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.key} value={country.key}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorFormData.countryCode.length > 0 ? (
                    <FormHelperText>{errorFormData.countryCode}</FormHelperText>
                  ) : null}
                </FormControl>
              </Box>
            </Section>
            <Section>
              <InputName>{t("company")}</InputName>
              <InputWrapper>
                <TextField
                  fullWidth
                  placeholder={t("companyPlaceholder") || ""}
                  value={formData.companyName}
                  variant="outlined"
                  autoComplete="organization"
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      companyName: event.target.value as string,
                    })
                  }
                />
              </InputWrapper>
            </Section>
            <Section>
              <InputName>{t("address")}</InputName>
              <InputWrapper>
                <TextField
                  autoComplete="address-line1"
                  placeholder={t("addressPlaceholder") || ""}
                  value={formData.line1}
                  variant="outlined"
                  fullWidth
                  required
                  onBlur={() =>
                    setIsBlurred({
                      ...isBlurred,
                      address: true,
                    })
                  }
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      line1: event.target.value as string,
                    })
                  }
                  error={isBlurred.address && errorFormData.line1.length > 0}
                  helperText={isBlurred.address && errorFormData.line1}
                  InputProps={{ sx: { marginBottom: 2 } }}
                />
                <TextField
                  fullWidth
                  autoComplete="address-line2"
                  placeholder={t("addressLine2Placeholder") || ""}
                  value={formData.line2}
                  variant="outlined"
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      line2: event.target.value as string,
                    })
                  }
                />
              </InputWrapper>
            </Section>
            <Section>
              <InputName>{t("city")}</InputName>
              <InputWrapper>
                <TextField
                  autoComplete="address-level2"
                  placeholder={t("cityPlaceholder") || ""}
                  value={formData.city}
                  variant="outlined"
                  fullWidth
                  required
                  onBlur={() =>
                    setIsBlurred({
                      ...isBlurred,
                      city: true,
                    })
                  }
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      city: event.target.value as string,
                    })
                  }
                  error={isBlurred.city && errorFormData.city.length > 0}
                  helperText={isBlurred.city && errorFormData.city}
                />
              </InputWrapper>
            </Section>
          </form>
        </div>
      </DialogContent>
      <DialogActions>
        <ButtonWrapper>
          <Button
            disabled={isDisabled}
            variant="contained"
            onClick={() => {
              setIsDisabled(true);
              setActionState({ isLoading: true });
              handleSubmit(formData, () => {
                edit ? handleEditSubmit() : handleAddSubmit();
              });
            }}
          >
            {actionState.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              renderButtonName
            )}
          </Button>
        </ButtonWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default AddAddressModal;
